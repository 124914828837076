<template>
  <router-view/>
</template>

<style lang="scss">
/* ubuntu-mono-regular - latin-ext_latin */
@font-face {
  font-family: 'Ubuntu Mono';
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url('./assets/fonts/ubuntu-mono/ubuntu-mono-v14-latin-ext_latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./assets/fonts/ubuntu-mono/ubuntu-mono-v14-latin-ext_latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* ubuntu-mono-700 - latin-ext_latin */
@font-face {
  font-family: 'Ubuntu Mono';
  font-style: normal;
  font-weight: 700;
  src: local(''),
  url('./assets/fonts/ubuntu-mono/ubuntu-mono-v14-latin-ext_latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
  url('./assets/fonts/ubuntu-mono/ubuntu-mono-v14-latin-ext_latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html, body {
  color: #ffffff;
  background: #1b1b1b;
  padding: 0;
  margin: 0;
  font-family: Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: 'Ubuntu Mono', monospace;
}

#app {
  //576,768,992,1200
}
</style>
