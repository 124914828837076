
import InlineSvg from 'vue-inline-svg'

document.addEventListener('copy', (event) => {
  const selection = document.getSelection()
  if (event.clipboardData && selection) {
    // replace email_space_kamil...  => replace space with at
    event.clipboardData.setData('text/plain', selection.toString().replace(/l\s+k/gm, 'l@k'))
    event.preventDefault()
  }
})

// Your component
export default {
  components: {
    InlineSvg
  },
  methods: {
    changeMailto (event: Event): void {
      const target = event.target as HTMLInputElement
      const mail = 'email'
      const domain = 'kamilpesek.cz'
      target.setAttribute('href', 'mailto:' + mail + '@' + domain)
    }
  }
}
